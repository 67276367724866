import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode
} from 'react';

import {
  GetCategoryList,
  GetCompany
} from '../services/_index.services';

import {
  ICategory,
  ICompany,
  IMenuCategories
} from '../interfaces/_index.interfaces';

export const CompanyId = "66378bffff3b0590149ce40d"; // Okearo
// export const CompanyId = "66a7c579e7d19917b885d8a3"; // Delidebru

export type TCompanyFetched = {
  name: string;
  banner: any[];
  primaryColorLight: string;
  secondaryColorLight: string;
  ThirdColorLight: string;
  primaryColorDark: string;
  secondaryColorDark: string;
  thirdColorDark: string;
};

export type TMenuFeatched = {
  categoriesList: IMenuCategories[];

};

// Defina o tipo para o contexto
interface CompanySiteContextType {
  companyFetched: TCompanyFetched | null;
  menuFetched: TMenuFeatched | null;
  loading: boolean;
}

// Crie o contexto
const CompanySiteContext = createContext<CompanySiteContextType | undefined>(undefined);

// Função para obter as cores da empresa
const fetchCompany = async (): Promise<TCompanyFetched | null> => {
  try {
    const resp = await GetCompany(CompanyId); // hardcoded
    const company: ICompany = resp?.data?.company;
    const colors = company?.layout?.colors;
    const banners = company?.layout?.banner;

    return {
      name: company.fantasyName,
      banner: banners,
      primaryColorLight: colors.primaryColorLight,
      secondaryColorLight: colors.secondaryColorLight,
      ThirdColorLight: colors.thirdColorLight,
      primaryColorDark: colors.primaryColorDark,
      secondaryColorDark: colors.secondaryColorDark,
      thirdColorDark: colors.thirdColorDark,
    };
  } catch (error) {
    console.error('Erro ao obter empresa:', error);
    return null;
  }
};

const fetchMenu = async (): Promise<TMenuFeatched | null> => {
  // const [categoriesList, setCategoriesList] = useState<IMenuCategories[]>([]);

  const resp: ICategory[] = await GetCategoryList({ company: CompanyId });
  const list = resp.map((item) => {
    return {
      _id: item._id,
      category: item.name,
      subCategories: item.subCategory.map((sub) => {
        return {
          _id: sub._id,
          subCategory: sub.subCategoryName,
        }
      })

    }
  });

  return {
    categoriesList: list,
  }

}

// Componente Provider para fornecer o contexto
export const CompanyFetchedProvider = ({ children }: { children: ReactNode }) => {
  const [companyFetched, setCompanyFetched] = useState<TCompanyFetched | null>(null);
  const [menuFetched, setMenuFetched] = useState<TMenuFeatched | null>(null);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const initializeCompanyFetched = async () => {
      const company = await fetchCompany();
      const menu = await fetchMenu();
      setCompanyFetched(company);
      setMenuFetched(menu);
      setLoading(false);
    };

    initializeCompanyFetched();
  }, []);

  return (
    <CompanySiteContext.Provider value={{ companyFetched, menuFetched, loading }}>
      {children}
    </CompanySiteContext.Provider>
  );
};

// Hook para usar o contexto
export default function useCompanyFetch() {
  const context = useContext(CompanySiteContext);
  if (context === undefined) {
    throw new Error('useCompanyFetch must be used within a CompanyFetchedProvider');
  }
  return context;
};
