import { useEffect, useState } from "react";

import {
  ICategory,
  IProduct,
  IProductOffers
} from "../../../interfaces/_index.interfaces";

import { GetCategoryList } from "../../../services/_index.services";
import { GetProductList } from "../../../services/products.services";
import { CompanyId } from "../../../layout/_index.layout";



type THomePageHooksProps = {};
export type THomePageHooksStates = THomePageHooksProps & {
  productsOfferList: IProduct[];
  categoriesList: ICategory[];
  categoriesListEmphasis: ICategory[];
  productOfferDay: IProduct;
  loading: boolean;
};



export const HomePageHooks = ({ ...props }: THomePageHooksProps): THomePageHooksStates => {

  const [productsOfferList, setProductsOfferList] = useState<IProduct[]>([]);
  const [categoriesList, setCategoriesList] = useState<ICategory[]>([]);
  const [categoriesListEmphasis, setCategoriesListEmphasis] = useState<ICategory[]>([]);
  const [productOfferDay, setProductOfferDay] = useState<IProduct | any>();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    async function getCategoriesList() {
      const resp = await GetCategoryList({ company: CompanyId });
      setCategoriesList(resp);
      setCategoriesListEmphasis(resp?.filter((item: ICategory) => item.isEmphasis))
    }

    async function getProductList() {
      const resp = await GetProductList({ company: CompanyId });
      setProductsOfferList(resp?.filter((item: IProduct) => item.emphasis));

      const productWithHighestDiscount = resp?.reduce((max, product) => {
        const discountPercentage = (product.realPrice - product.promotionPrice) / product.realPrice;
        const maxDiscountPercentage = (max.realPrice - max.promotionPrice) / max.realPrice;
        return discountPercentage > maxDiscountPercentage ? product : max;
      }, resp[0]);

      setProductOfferDay(productWithHighestDiscount);
      setLoading(false);
    }

    getCategoriesList();
    getProductList();
  }, []);

  return {
    ...props,
    productsOfferList,
    categoriesList,
    categoriesListEmphasis,
    productOfferDay,
    loading,
  };
};